<template>
  <div class="fullscreen">
    <br>
    <div v-if="signURL != null" class="fullscreen">
      <div id="hello-sign-container" />
    </div>
    <div v-else>
      Hello Sign for Mobile
    </div>
    <div>
      fsDealerID: {{ this.fsDealerID }}
    </div>
  </div>
</template>

<script>
import HelloSign from 'hellosign-embedded';
export default {
  data() {
    return {
      signURL: null,
      clientId: null,
      fsDealerID: null,
      device: null // Android = 0 & iOS = 1
    };
  },
  created() {
    this.fsDealerID = window.sessionStorage.getItem('fsDealerID');
    const urlParams = new URLSearchParams(window.location.search);
    if (
      urlParams.has('signurl') &&
      urlParams.has('clientId') &&
      urlParams.has('device')
    ) {
      this.signURL = urlParams.get('signurl');
      this.clientId = urlParams.get('clientId');
      this.device = parseInt(urlParams.get('device'));
    } else {
      this.sendMessageToMobile('NO_PARAMETER');
    }
  },
  mounted() {
    setTimeout(() => {
      if (window.Intercom) {
        window.Intercom('update', { hide_default_launcher: true });
      }
    }, 15000);

    if (this.signURL && this.clientId && this.device != null) {
      this.openHelloSign();
    }
  },
  methods: {
    openHelloSign() {
      let helloSignData = this.signURL;
      const that = this;

      if (helloSignData != null) {
        this.sendMessageToMobile('WILL_OPEN');

        const client = new HelloSign({
          clientId: that.clientId
        });

        client.open(helloSignData, {
          skipDomainVerification: true,
          allowCancel: false,
          timeout: 60000,
          container: document.querySelector('#hello-sign-container'),
          whiteLabeling: {
            primary_button_color: '#FA5555',
            secondary_button_text_color: '#FA5555'
          }
        });

        client.on(HelloSign.events.READY, (data) => {
          console.log('hellosign READY', data);
          this.sendMessageToMobile('READY');
        });

        client.on(HelloSign.events.DECLINE, (data) => {
          console.log('hellosign decline', data);
          this.sendMessageToMobile('DECLINE');
        });

        client.on(HelloSign.events.SIGN, (data) => {
          console.log('The signature request was signed!', data);
          this.sendMessageToMobile('SIGN');
          // client.close();
        });

        client.on(HelloSign.events.CANCEL, () => {
          console.log('The signature request was canceled!');
          this.sendMessageToMobile('CANCEL');
          // client.close();
        });

        client.on(HelloSign.events.ERROR, (data) => {
          console.log(
            `Something went wrong! Please try again. Error code: ${data.code}`
          );
          this.sendMessageToMobile('ERROR');
          // client.close();
        });

        client.on(HelloSign.events.CLOSE, () => {
          console.log('The signature request was close!');
          this.sendMessageToMobile('CLOSE');
          // client.close();
        });
      } else {
        this.sendMessageToMobile('SIGN_URL_NOT_FOUND');
      }
    },
    sendMessageToMobile(message) {
      if (this.device == 1) {
        // iOS
        try {
          window.webkit.messageHandlers.hellosignResultCallbackHandler.postMessage(
            message
          );
        } catch (err) {
          console.log('iOS Sending Failed : ', message);
          console.log('Error Sending Message to Mobile : ', err);
        }
      } else {
        try {
          window.Android.sendData(message);
        } catch (err) {
          console.log('Android Sending Failed : ', message);
          console.log('Error Sending Message to Mobile : ', err);
        }
      }
    }
  }
};
</script>

<style>
#hello-sign-container {
  height: 100%;
  width: 100%;
}

html,
body {
  height: 100%;
}
#main {
  height: 100%;
}
#app {
  height: 100%;
}

.fullscreen {
  height: 100%;
  width: 100%;
}

.hide-mobile {
  @media (max-width: 991.98px) {
    display: none;
  }
}
</style>